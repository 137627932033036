import { capitalize, formatMoney, formatPickupDate, plural } from '@helpers/display'
import { isNonNullish } from '@helpers/helpers'
import { MoneyCalc } from '@helpers/money'
import { calculatePayments, getPaymentSchedules, getProratedAmount, isProrated } from '@helpers/order'
import { sortByProperty } from '@helpers/sorting'
import { isSameDay } from '@helpers/time'
import { LocationTypes, isDelivery, isShipping } from '@models/Location'
import { CartItem, CartShare, isCartShare } from '@models/Order'
import { PaymentInterval, PaymentType } from '@models/Payment'
import { PaymentSchedule, hasUnits, isShare } from '@models/Product'
import { DateTime } from 'luxon'

import { Cart } from '@models/Cart'
import { Farm } from '@models/Farm'
import { PayScheduleExt } from './CartButtons'

export const makeTestId = (itemId: string, ps: PaymentSchedule): string => `${itemId}-${ps.paymentType}-${ps.frequency}`

/** Helper to detect if a payment interval is an installment payment.
 * TODO: Can be improved if we can mark which payments are installments then we wouldn't need to guess by subtracting
 *  deposit from total. */
export const isInstallment = (payInt: PaymentInterval, paySchedule: CartItem['paymentSchedule'], quantity: number) =>
  MoneyCalc.isGTZero(MoneyCalc.subtract(payInt.subtotal, MoneyCalc.multiply(paySchedule.deposit, quantity)))

/** Will get the first payment interval that could be an installment. This could be the first, or second payment if there is a deposit. */
export const get1stInstallment = (
  payInts: PaymentInterval[],
  paySchedule: CartItem['paymentSchedule'],
  quantity: number,
): PaymentInterval => {
  const ix = isInstallment(payInts[0], paySchedule, quantity) ? 0 : 1
  return payInts[ix]
}

const installmentsStartDateTxt = (payment: PaymentSchedule, payInts: PaymentInterval[], quantity: number): string => {
  const firstInstallment = get1stInstallment(payInts, payment, quantity)

  if (isSameDay(firstInstallment.date, DateTime.now())) return 'today'
  return formatPickupDate(firstInstallment.date)
}

// Gets the number of installments for a seasonal share
const numberOfInstallments = (payment: PaymentSchedule, payInts: PaymentInterval[], quantity: number): number => {
  let numPays = payInts.length
  // Since the first payment is the only one that could be an installment, we are only checking that
  if (!isInstallment(payInts[0], payment, quantity)) numPays--
  return numPays
}

// Gets the deposit, factoring in prorated prices
const depositMessage = (item: CartShare, schedule: PayScheduleExt): string => {
  const deposit = MoneyCalc.multiply(schedule.deposit, item.quantity)
  if (!isProrated(item) && deposit.value > 0) return ` and a ${formatMoney(deposit)} deposit payment today`
  return ''
}

const freqSingularNouns = {
  ONCE: 'once',
  WEEKLY: 'week',
  MONTHLY: 'month',
  'PER-PICKUP': 'pickup',
}
const freqPluralNouns = {
  ONCE: 'once',
  WEEKLY: 'weeks',
  MONTHLY: 'months',
  'PER-PICKUP': 'pickups',
}
const getFreqNoun = (freq: PaymentSchedule['frequency'], nPickups = 1) => {
  return freq === 'PER-PICKUP' ? 'distributions' : plural(nPickups, freqSingularNouns[freq], freqPluralNouns[freq])
}
const getFrequencyDisplay = (freq: PaymentSchedule['frequency'], locType: LocationTypes = LocationTypes.FARM) => {
  if (freq === 'PER-PICKUP' && isDelivery({ type: locType })) return 'per-delivery'
  if (freq === 'PER-PICKUP' && isShipping({ type: locType })) return 'per-shipment'
  return freq.toLowerCase()
}

/** Generates the title for each paySchedule checkbox */
export function makeTitle(paySchedule: PayScheduleExt, locType: LocationTypes = LocationTypes.FARM) {
  let title: string
  if (paySchedule.frequency === 'ONCE') title = `Pay-in-full`
  else if (paySchedule.frequency === 'PER-PICKUP' && isDelivery({ type: locType })) title = 'Per-delivery Payments'
  else if (paySchedule.frequency === 'PER-PICKUP' && isShipping({ type: locType })) title = 'Per-shipment Payments'
  else title = `${capitalize(paySchedule.frequency)} Payments`

  let discount = ''
  if (paySchedule.discount && paySchedule.discount > 0) {
    const discountNum = paySchedule.discount.toFixed().toString()
    discount = ` (${discountNum}% off)`
  }
  return title + discount
}

type processPaySchedulesOpts = Pick<Partial<Cart>, 'isAdmin'> & {
  /** Additional options that can be passed to alter the invoice creation such as enforcing timezone or a due date tolerance */
  farm: Pick<Farm, 'id' | 'timezone' | 'dueDateTolerance'> | undefined
  /** Whether this is being placed as a wholesale order or not */
  isWholesale?: boolean
}

/** Computes related data about the item.product.paymentSchedules */
export const processPaySchedules = (
  item: CartItem,
  { isAdmin = false, isWholesale = false, farm }: processPaySchedulesOpts,
): PayScheduleExt[] => {
  let newPaySchedules: PayScheduleExt[] = getPaymentSchedules(item)

  //Get the invoice interval of each pay-schedule
  newPaySchedules = newPaySchedules
    .map((paySchedule: PaymentSchedule) => {
      const intervalItem: CartItem = { ...item }

      intervalItem.paymentSchedule = paySchedule

      const interval = calculatePayments(
        { items: [intervalItem], isAdmin, discounts: {} },
        { isWholesale, farmId: farm?.id, dueDateTolerance: farm?.dueDateTolerance, timezone: farm?.timezone },
      )
      if (!interval) return

      return {
        ...paySchedule,
        interval,
      }
    })
    .filter(isNonNullish)

  if (isShare(item.product)) {
    //Get prorated price of each schedule
    newPaySchedules = newPaySchedules
      .map((paySchedule) => {
        const proratedAmtItem: CartItem = { ...item }
        proratedAmtItem.paymentSchedule = paySchedule
        return {
          ...paySchedule,
          proratedPrice:
            getProratedAmount(proratedAmtItem, {
              excludeClosedDistros: !isAdmin,
              ignoreOrderCutoffWindow: isAdmin,
            }).itemAmount ?? undefined,
        }
      })
      .filter((ps) => isNonNullish(ps.proratedPrice))
    //Get discounts of each schedule based on max price
    const maxProratedPrice = Math.max(...newPaySchedules.map((s) => s.proratedPrice!))
    newPaySchedules = newPaySchedules.map((paySchedule) => {
      let discount: number | undefined = undefined
      if (paySchedule.proratedPrice) {
        discount = (1 - paySchedule.proratedPrice / maxProratedPrice) * 100
      }
      // Apply discount only if the calculation is GTE 1 (ie. should not be 0.01)
      discount = discount && discount >= 1 ? discount : undefined
      return { ...paySchedule, discount }
    })

    //Sort by default order
    newPaySchedules.sort(sortByProperty(['ONCE', 'MONTHLY', 'WEEKLY'], (item: PayScheduleExt) => item.frequency))
  }
  return newPaySchedules
}

/** Shows a short text description about the payment options for a given item in cart.
 * @param item the cart item to get the payment schedule text for
 * @param psDetails is an array of objects that correspond to each of the payment schedules for the item. It is expected that the item's payment schedule will have a corresponding details object that matches on the same frequency
 */
export function paymentScheduleText(item: CartItem, psDetails: PayScheduleExt[]): string | null {
  const paySchedule = psDetails.find((ps) => ps.frequency === item.paymentSchedule.frequency)
  if (!paySchedule || (paySchedule.paymentType === PaymentType.PAY_FULL && hasUnits(item.product))) return null

  // Do not if amounts are $0
  if (MoneyCalc.isZero(paySchedule.amount) && MoneyCalc.isZero(paySchedule.amount)) return null

  const frequency = getFrequencyDisplay(paySchedule.frequency, item.distribution?.location.type)
  const freqPlural = getFreqNoun(paySchedule.frequency, item.pickups?.length)
  const depositMsg = isCartShare(item) ? depositMessage(item, paySchedule) : ''
  const n_installments = numberOfInstallments(paySchedule, paySchedule.interval ?? [], item.quantity)
  const startDate = installmentsStartDateTxt(paySchedule, paySchedule.interval ?? [], item.quantity)

  // For pay in full or installments with just 1 payment today
  if (paySchedule.interval?.length === 1) {
    // Don't need to show any text if it is pay in full for today
    if (startDate === 'today') return ''

    return `You will pay ${formatMoney(paySchedule.interval?.[0]?.total ?? null)} ${startDate}`
  }

  // Index 0 is upfront payment, after that are the installment payments. So we get index 0 for payInFull, and index 1 for installments.
  const index = paySchedule.paymentType === PaymentType.PAY_FULL ? 0 : 1
  const amount = formatMoney(paySchedule.interval?.[index]?.total ?? null)

  return `You will pay ${amount} ${frequency} for ${n_installments} ${freqPlural} starting ${startDate}${depositMsg}`
}
