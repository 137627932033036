import { OrderCancelPreflightResponse } from '@shared/types/v2/order'
import { memo, useCallback, useMemo } from 'react'
import { AdminOrderCancelProps } from '../../ModalAdminOrderCancel'

import { formatMoney } from '@helpers/display'
import { getZero, MoneyCalc, withCurrency } from '@helpers/money'
import { CurrencyCode, Money, MoneyWithCurrency } from '@models/Money'
import { View } from 'react-native'
import { claimedPickupRatio, confirmCancelOrder, paidAndTotalAmountRatio } from '../../helpers/orderCancelHelper'

import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useDeepCompareFocusFx } from '@/hooks/useFocusFx'
import useKeyedState from '@/hooks/useKeyedState'
import { adminCurrencySelector, adminFarmSelector } from '@/redux/selectors'
import { Button, Divider, ErrorText, Picker, Text, TextH2 } from '@elements'
import { getServiceFeeForPayment } from '@helpers/serviceFee'
import { entries, keys } from '@helpers/typescript'
import { PaymentSources } from '@models/PaymentMethod'
import { SourceAmountsTracker, TrackedSourceAmounts } from '@shared/helpers/AmountTrackers/SourceAmountsTracker'
import { useSelector } from 'react-redux'
import { ItemRow } from './ItemRow'

type OrderCancelProps = AdminOrderCancelProps & {
  /** @orderCancelPreflightResponse refers to the response from orderCancelPreflight api */
  orderCancelPreflightResponse: OrderCancelPreflightResponse
  /** @chosenDate is string of ISO time and it is the start date of cancellation */
  chosenDate: string
}

enum RefundType {
  Refund_deduct_Processing_Fees = 'refund_deduct_processing_fees',
  Refund_In_Full = 'refund_in_full',
  No_Refund = 'no_refund',
}

/** stripe handle different payment type. stripe => credit card, farmCredit => farmCredit  */
enum StripePaymentType {
  StripeGroups = 'stripeGroups',
  FarmCredit = 'farmCredit',
}

/** stripePaymentOption is used in stripe payment methods Picker like credit card and farm credit */
const StripePaymentOptions: { label: string; value: string }[] = [
  { label: 'Bank & Credit Card', value: StripePaymentType.StripeGroups },
  { label: 'Farm Credit', value: StripePaymentType.FarmCredit },
]

const defaultStripePaymentOption = StripePaymentOptions[0]
const additionalStripePaymentOptions = StripePaymentOptions.slice(1)

/** This is passed in Refund Payment Methods section and only top payment method will display this */
const titleDefaultPaymentMethodSection = { leftSideValues: ['Refund Payment Methods'], leftOpts: { isTitleLeft: true } }

/** This is passed in Refund Summary Section and only top refund source will display this */
const titleDefaultRefundSummarySection = { leftSideValues: ['Refund Summary'], leftOpts: { isTitleLeft: true } }

/** all keyStates props */
type KeyStates = {
  isLoading: boolean
  /** refundType means different refund options */
  refundType: string
  /** stripePaymentType includes stripeGroups (stripe and stripeACH) or farm credit */
  stripePaymentType: StripePaymentType
  /** refundAmountsData holds all related data for refundAmounts */
  refundAmountsData: {
    /** customer refund amount */
    customerRefundAmount: Money
    /** adjustedRefundAmount will be applied when any pattern are going to impact final refund amounts like, RefundType.Refund_deduct_Processing_Fees is chosen or stripePaymentType is changed */
    adjustedRefundAmount: Required<TrackedSourceAmounts>
  }
}

export const OrderCancelView = memo(function OrderCancel({
  orderCancelPreflightResponse,
  currentOrder,
  cancelledItems,
  entireOrder,
  onClose,
  chosenDate,
}: OrderCancelProps) {
  const farm = useSelector(adminFarmSelector)
  const currency = useSelector(adminCurrencySelector)

  const styles = useStyles()
  const [{ isLoading, refundType, stripePaymentType, refundAmountsData }, set] = useKeyedState<KeyStates>({
    isLoading: false,
    refundType: RefundType.Refund_deduct_Processing_Fees,
    stripePaymentType: StripePaymentType.StripeGroups,
    refundAmountsData: {
      customerRefundAmount: getZero(currency),
      adjustedRefundAmount: SourceAmountsTracker.generateZeroSourceAmounts(currency),
    },
  })

  const refundAmountsTracker = new SourceAmountsTracker(currency, orderCancelPreflightResponse.refundAmounts)

  const stripe = refundAmountsTracker.getSourceAmount(PaymentSources.STRIPE)
  const stripe_ach = refundAmountsTracker.getSourceAmount(PaymentSources.STRIPE_ACH)
  const fortis_card = refundAmountsTracker.getSourceAmount(PaymentSources.FORTIS_CARD_ONLINE)
  const fortis_ach = refundAmountsTracker.getSourceAmount(PaymentSources.FORTIS_ACH)
  const offline = refundAmountsTracker.getSourceAmount(PaymentSources.OFFLINE)
  const farmCredit = refundAmountsTracker.getSourceAmount(PaymentSources.FARM_CREDIT)
  const ebtSNAP = refundAmountsTracker.getSourceAmount(PaymentSources.WORLD_PAY_EBT_SNAP)
  const ebtCASH = refundAmountsTracker.getSourceAmount(PaymentSources.WORLD_PAY_EBT_CASH)

  const { contributeTips, maxRefundAmounts, refundAmounts } = orderCancelPreflightResponse

  /** get any source processing fee */
  const getSourceProcessingFee = useCallback(
    (source: PaymentSources, amount: Money): MoneyWithCurrency => {
      const fee = MoneyCalc.isZero(amount)
        ? getZero(currency)
        : withCurrency(getServiceFeeForPayment(source, amount, farm.pricingModel?.appFeePercent), currency)

      if (!contributeTips) return fee

      const refundedAmount = MoneyCalc.min(MoneyCalc.add(amount, fee), maxRefundAmounts[source] ?? getZero(currency))
      return MoneyCalc.max(MoneyCalc.subtract(refundedAmount, amount), getZero(currency))
    },
    [contributeTips, farm.pricingModel?.appFeePercent, maxRefundAmounts, currency],
  )

  /** refundOptions is used in refund Picker */
  const refundOptions: { label: string; value: string }[] = useMemo(
    () => [
      {
        label: `Refund and deduct non-refundable processing fees (${formatMoney(
          MoneyCalc.add(
            getSourceProcessingFee(PaymentSources.STRIPE, stripe),
            getSourceProcessingFee(PaymentSources.STRIPE_ACH, stripe_ach),
            getSourceProcessingFee(PaymentSources.FORTIS_CARD_ONLINE, fortis_card),
            getSourceProcessingFee(PaymentSources.FORTIS_ACH, fortis_ach),
          ),
        )})`,
        value: RefundType.Refund_deduct_Processing_Fees,
      },
      { label: 'Refund in full', value: RefundType.Refund_In_Full },
      { label: 'No Refund', value: RefundType.No_Refund },
    ],
    [getSourceProcessingFee, stripe, stripe_ach, fortis_card, fortis_ach],
  )

  const defaultRefundOption = useMemo(() => refundOptions[0], [refundOptions])
  const additionalRefundOptions = useMemo(() => refundOptions.slice(1), [refundOptions])
  const noRefundOption = useMemo(() => refundOptions[2], [refundOptions])

  /** One invoice can be paid by any other source even if first time the order purchased by Offline */
  const isOffline = MoneyCalc.isGTZero(offline) && keys(refundAmounts).length === 1

  /** firstRefundMethod object holds different paymentMethods. If value for a paymentMethod is true, It is should be listed as first paymentMethod. */
  const firstRefundMethod = arrangePaymentsOrder(currency, refundAmounts)

  /** this memo is used to set refundType when there is actually no refund and return boolean value */
  const isNoValueToRefund = useMemo(() => {
    const total = entries(refundAmounts).reduce((acc, [source, value]) => {
      if (source === PaymentSources.OFFLINE) return acc
      return MoneyCalc.add(acc, value)
    }, getZero(currency))
    if (MoneyCalc.isZero(total)) {
      set('refundType', RefundType.No_Refund)
      return true
    }
    return false
  }, [refundAmounts, set, currency])

  /** this deepCompareEffect is used to adjust refundAmountsData and depends on refundType and stripePaymentType  */
  useDeepCompareFocusFx(() => {
    // all stripe refund goes with farm credit only.
    const allWithFarmCredit = stripePaymentType === StripePaymentType.FarmCredit
    const stripeProcessingFee = getSourceProcessingFee(PaymentSources.STRIPE, stripe)
    const stripeACHProcessingFee = getSourceProcessingFee(PaymentSources.STRIPE_ACH, stripe_ach)
    const fortisCCProcessingFee = getSourceProcessingFee(PaymentSources.FORTIS_CARD_ONLINE, fortis_card)
    const fortisACHProcessingFee = getSourceProcessingFee(PaymentSources.FORTIS_ACH, fortis_ach)
    const adjustedRefundAmountTracker = new SourceAmountsTracker(currency, refundAmounts)

    if (refundType === RefundType.No_Refund) {
      return set('refundAmountsData', {
        customerRefundAmount: getZero(currency),
        adjustedRefundAmount: SourceAmountsTracker.generateZeroSourceAmounts(currency),
      })
    } else if (refundType === RefundType.Refund_In_Full) {
      let customerRefundAmount = entries(refundAmounts).reduce((acc, [source, value]) => {
        if (source === PaymentSources.OFFLINE) return acc
        return MoneyCalc.add(acc, value)
      }, getZero(currency))

      // Add processing fee to the customerRefundAmount if contributeTips is true
      if (contributeTips) {
        customerRefundAmount = MoneyCalc.add(
          customerRefundAmount,
          stripeProcessingFee,
          stripeACHProcessingFee,
          fortisCCProcessingFee,
          fortisACHProcessingFee,
        )
      }

      const stripeWithProcessingFee = contributeTips ? MoneyCalc.add(stripe, stripeProcessingFee) : stripe
      const stripeACHWithProcessingFee = contributeTips ? MoneyCalc.add(stripe_ach, stripeACHProcessingFee) : stripe_ach
      const fortisCCWithProcessingFee = contributeTips ? MoneyCalc.add(fortis_card, fortisCCProcessingFee) : fortis_card
      const fortisACHWithProcessingFee = contributeTips ? MoneyCalc.add(fortis_ach, fortisACHProcessingFee) : fortis_ach

      adjustedRefundAmountTracker.setSourceAmount(
        PaymentSources.STRIPE,
        allWithFarmCredit ? getZero(currency) : stripeWithProcessingFee,
      )
      adjustedRefundAmountTracker.setSourceAmount(
        PaymentSources.STRIPE_ACH,
        allWithFarmCredit ? getZero(currency) : stripeACHWithProcessingFee,
      )
      adjustedRefundAmountTracker.setSourceAmount(
        PaymentSources.FORTIS_CARD_ONLINE,
        allWithFarmCredit ? getZero(currency) : fortisCCWithProcessingFee,
      )
      adjustedRefundAmountTracker.setSourceAmount(
        PaymentSources.FORTIS_ACH,
        allWithFarmCredit ? getZero(currency) : fortisACHWithProcessingFee,
      )
      adjustedRefundAmountTracker.setSourceAmount(
        PaymentSources.FARM_CREDIT,
        allWithFarmCredit ? MoneyCalc.add(stripeWithProcessingFee, stripeACHWithProcessingFee, farmCredit) : farmCredit,
      )

      return set('refundAmountsData', {
        customerRefundAmount,
        adjustedRefundAmount: adjustedRefundAmountTracker.displayAllSourceAmounts(),
      })
    } else {
      // Deduct processing fee to the refund amount which is calculated by getStripeServiceFeeForPayment if the customer did not contribute tips
      const stripeWithoutProcessingFee = contributeTips ? stripe : MoneyCalc.subtract(stripe, stripeProcessingFee)
      const stripeACHWithoutProcessingFee = contributeTips
        ? stripe_ach
        : MoneyCalc.subtract(stripe_ach, stripeACHProcessingFee)
      const fortisCCWithoutProcessingFee = contributeTips
        ? fortis_card
        : MoneyCalc.subtract(fortis_card, fortisCCProcessingFee)
      const fortisACHWithoutProcessingFee = contributeTips
        ? fortis_ach
        : MoneyCalc.subtract(fortis_ach, fortisACHProcessingFee)

      let customerRefundAmount = entries(refundAmounts).reduce((acc, [source, value]) => {
        if (source === PaymentSources.OFFLINE) return acc
        return MoneyCalc.add(acc, value)
      }, getZero(currency))

      // Deduct processing fee to the customerRefundAmount if the customer did not contribute tips
      if (!contributeTips) {
        customerRefundAmount = MoneyCalc.subtract(
          customerRefundAmount,
          MoneyCalc.add(stripeProcessingFee, stripeACHProcessingFee),
        )
      }

      adjustedRefundAmountTracker.setSourceAmount(
        PaymentSources.STRIPE,
        allWithFarmCredit ? getZero(currency) : stripeWithoutProcessingFee,
      )
      adjustedRefundAmountTracker.setSourceAmount(
        PaymentSources.STRIPE_ACH,
        allWithFarmCredit ? getZero(currency) : stripeACHWithoutProcessingFee,
      )
      adjustedRefundAmountTracker.setSourceAmount(
        PaymentSources.FORTIS_CARD_ONLINE,
        allWithFarmCredit ? getZero(currency) : fortisCCWithoutProcessingFee,
      )
      adjustedRefundAmountTracker.setSourceAmount(
        PaymentSources.FORTIS_ACH,
        allWithFarmCredit ? getZero(currency) : fortisACHWithoutProcessingFee,
      )
      adjustedRefundAmountTracker.setSourceAmount(
        PaymentSources.FARM_CREDIT,
        allWithFarmCredit
          ? MoneyCalc.add(stripeWithoutProcessingFee, stripeACHWithoutProcessingFee, farmCredit)
          : farmCredit,
      )

      return set('refundAmountsData', {
        customerRefundAmount,
        adjustedRefundAmount: adjustedRefundAmountTracker.displayAllSourceAmounts(),
      })
    }
  }, [
    contributeTips,
    farmCredit,
    getSourceProcessingFee,
    refundAmounts,
    refundType,
    set,
    stripe,
    stripePaymentType,
    stripe_ach,
    fortis_card,
    fortis_ach,
    currency,
  ])

  /** the function to process data and request server to process refunds */
  const confirmCancellation = useCallback(() => {
    const refundAmounts = new SourceAmountsTracker(
      currency,
      refundAmountsData.adjustedRefundAmount,
    ).getAllSourceAmounts(true)

    // Offline payment should be refunded to the customer manually
    delete refundAmounts[PaymentSources.OFFLINE]

    set('isLoading', true)
    confirmCancelOrder(currentOrder, cancelledItems, refundAmounts, entireOrder, chosenDate).then(() => {
      if (onClose) onClose()
      set('isLoading', false)
    })
  }, [
    cancelledItems,
    chosenDate,
    currentOrder,
    entireOrder,
    onClose,
    refundAmountsData.adjustedRefundAmount,
    set,
    currency,
  ])

  return (
    <View>
      {/* Items section */}
      <View>
        <View style={[styles.itemInfoCont, styles.marginBottom5]}>
          <View style={globalStyles.flex1}>
            <TextH2 type="bold" size={12}>
              Item Name
            </TextH2>
          </View>

          <View style={globalStyles.flex1}>
            <TextH2 type="bold" size={12}>
              Paid/Total Price
            </TextH2>
          </View>

          <View style={globalStyles.flex05}>
            <TextH2 type="bold" size={12}>
              Received
            </TextH2>
          </View>
        </View>
        {cancelledItems.map((item, idx) => {
          const { pickupCounters, invoiceCounters } = orderCancelPreflightResponse!

          return (
            <View key={item.id + '_' + idx} style={styles.itemInfoCont}>
              <View style={globalStyles.flex1}>
                <Text size={10}>
                  {item.product.name} x{item.quantity}
                </Text>
                {item.product.cancellationPolicy && (
                  <Text size={10} color="gray">
                    {item.product.cancellationPolicy}
                  </Text>
                )}
              </View>

              <View style={globalStyles.flex1}>
                <Text size={10}>{paidAndTotalAmountRatio(item.id, { invoiceCounters })}</Text>
              </View>

              <View style={globalStyles.flex05}>
                <Text size={10}>{claimedPickupRatio(item.id, { pickupCounters })}</Text>
              </View>
            </View>
          )
        })}
      </View>

      <Divider top={10} bottom={5} />

      {/* Choose Refund Type section */}
      <View>
        <View style={styles.refundOptionsCont}>
          <Text size={12} type="bold">
            How do you want to refund?
          </Text>
          <Picker
            style={styles.refundOptionsPickerCont}
            disabled={isNoValueToRefund}
            value={refundType}
            items={isOffline ? [noRefundOption] : additionalRefundOptions}
            placeholder={isOffline ? noRefundOption : defaultRefundOption}
            onValueChange={(val) => set('refundType', val)}
          />
          {contributeTips && refundType === RefundType.Refund_In_Full && (
            <Text style={globalStyles.marginVertical10}>This amount does not include customer contributed Tips</Text>
          )}
        </View>
        <ItemRow
          leftSideValues={['Refund Amount']}
          leftOpts={{ isTitleLeft: true }}
          rightOpts={{ color: Colors.black }}
          rightSideValues={['Customer Refund', formatMoney(refundAmountsData.customerRefundAmount)]}
        />
      </View>

      <Divider top={30} bottom={5} />

      {/* Show offline message here because offline will be forced to be no refund option */}
      {isOffline && (
        <ErrorText>{formatMoney(offline)} was paid offline and should be refunded manually to the customer.</ErrorText>
      )}

      {/** Show this section only if refundType is not no_refund */}
      {refundType !== RefundType.No_Refund && (
        <>
          {/* Refund Payment Methods section */}
          <View>
            {(MoneyCalc.isGTZero(stripe) ||
              MoneyCalc.isGTZero(stripe_ach) ||
              MoneyCalc.isGTZero(fortis_card) ||
              MoneyCalc.isGTZero(fortis_ach)) && (
              <ItemRow
                {...(firstRefundMethod[PaymentSources.STRIPE] ||
                firstRefundMethod[PaymentSources.STRIPE_ACH] ||
                firstRefundMethod[PaymentSources.FORTIS_CARD_ONLINE] ||
                firstRefundMethod[PaymentSources.FORTIS_ACH]
                  ? titleDefaultPaymentMethodSection
                  : {})}
                rightSideValues={[
                  <Picker
                    style={styles.pickerCont}
                    key="paymentPicker"
                    items={additionalStripePaymentOptions}
                    placeholder={defaultStripePaymentOption}
                    onValueChange={(val) => set('stripePaymentType', val as StripePaymentType)}
                  />,
                ]}
              />
            )}

            {MoneyCalc.isGTZero(farmCredit) && stripePaymentType !== StripePaymentType.FarmCredit && (
              <ItemRow
                {...(firstRefundMethod[PaymentSources.FARM_CREDIT] ? titleDefaultPaymentMethodSection : {})}
                rightSideValues={['Farm Credit']}
                rightOpts={{ color: Colors.black }}
              />
            )}
            {MoneyCalc.isGTZero(ebtSNAP) && (
              <ItemRow
                {...(firstRefundMethod[PaymentSources.WORLD_PAY_EBT_SNAP] ? titleDefaultPaymentMethodSection : {})}
                rightSideValues={['EBT SNAP']}
                rightOpts={{ color: Colors.black }}
              />
            )}
            {MoneyCalc.isGTZero(ebtCASH) && (
              <ItemRow
                {...(firstRefundMethod[PaymentSources.WORLD_PAY_EBT_CASH] ? titleDefaultPaymentMethodSection : {})}
                rightSideValues={['EBT CASH']}
                rightOpts={{ color: Colors.black }}
              />
            )}
          </View>
          <Divider top={30} bottom={5} />

          {/* Refund Summary */}
          {MoneyCalc.isGTZero(refundAmountsData.adjustedRefundAmount[PaymentSources.STRIPE]) && (
            <ItemRow
              {...(firstRefundMethod[PaymentSources.STRIPE] ? titleDefaultRefundSummarySection : {})}
              rightSideValues={[
                'Credit Card',
                formatMoney(refundAmountsData.adjustedRefundAmount[PaymentSources.STRIPE]),
              ]}
            />
          )}
          {MoneyCalc.isGTZero(refundAmountsData.adjustedRefundAmount[PaymentSources.STRIPE_ACH]) && (
            <ItemRow
              {...(firstRefundMethod[PaymentSources.STRIPE_ACH] ? titleDefaultRefundSummarySection : {})}
              rightSideValues={['Bank', formatMoney(refundAmountsData.adjustedRefundAmount[PaymentSources.STRIPE_ACH])]}
            />
          )}
          {MoneyCalc.isGTZero(refundAmountsData.adjustedRefundAmount[PaymentSources.FORTIS_CARD_ONLINE]) && (
            <ItemRow
              {...(firstRefundMethod[PaymentSources.FORTIS_CARD_ONLINE] ? titleDefaultRefundSummarySection : {})}
              rightSideValues={[
                'Credit Card',
                formatMoney(refundAmountsData.adjustedRefundAmount[PaymentSources.FORTIS_CARD_ONLINE]),
              ]}
            />
          )}
          {MoneyCalc.isGTZero(refundAmountsData.adjustedRefundAmount[PaymentSources.FORTIS_ACH]) && (
            <ItemRow
              {...(firstRefundMethod[PaymentSources.FORTIS_ACH] ? titleDefaultRefundSummarySection : {})}
              rightSideValues={['Bank', formatMoney(refundAmountsData.adjustedRefundAmount[PaymentSources.FORTIS_ACH])]}
            />
          )}
          {MoneyCalc.isGTZero(refundAmountsData.adjustedRefundAmount[PaymentSources.FARM_CREDIT]) && (
            <ItemRow
              // show title for farm credit only if the first refund method is farm credit or if stripe and stripeACH are zero and farm credit is greater than zero
              {...(firstRefundMethod[PaymentSources.FARM_CREDIT] ||
              ((firstRefundMethod[PaymentSources.STRIPE] ||
                firstRefundMethod[PaymentSources.STRIPE_ACH] ||
                firstRefundMethod[PaymentSources.FORTIS_CARD_ONLINE] ||
                firstRefundMethod[PaymentSources.FORTIS_ACH]) &&
                MoneyCalc.isZero(refundAmountsData.adjustedRefundAmount[PaymentSources.STRIPE]) &&
                MoneyCalc.isZero(refundAmountsData.adjustedRefundAmount[PaymentSources.STRIPE_ACH]) &&
                MoneyCalc.isZero(refundAmountsData.adjustedRefundAmount[PaymentSources.FORTIS_CARD_ONLINE]) &&
                MoneyCalc.isZero(refundAmountsData.adjustedRefundAmount[PaymentSources.FORTIS_ACH]) &&
                MoneyCalc.isGTZero(refundAmountsData.adjustedRefundAmount[PaymentSources.FARM_CREDIT]))
                ? titleDefaultRefundSummarySection
                : {})}
              rightSideValues={[
                'Farm Credit',
                formatMoney(refundAmountsData.adjustedRefundAmount[PaymentSources.FARM_CREDIT]),
              ]}
            />
          )}
          {MoneyCalc.isGTZero(refundAmountsData.adjustedRefundAmount[PaymentSources.WORLD_PAY_EBT_SNAP]) && (
            <ItemRow
              {...(firstRefundMethod[PaymentSources.WORLD_PAY_EBT_SNAP] ? titleDefaultRefundSummarySection : {})}
              rightSideValues={[
                'EBT SNAP',
                formatMoney(refundAmountsData.adjustedRefundAmount[PaymentSources.WORLD_PAY_EBT_SNAP]),
              ]}
            />
          )}
          {MoneyCalc.isGTZero(refundAmountsData.adjustedRefundAmount[PaymentSources.WORLD_PAY_EBT_CASH]) && (
            <ItemRow
              {...(firstRefundMethod[PaymentSources.WORLD_PAY_EBT_CASH] ? titleDefaultRefundSummarySection : {})}
              rightSideValues={[
                'EBT CASH',
                formatMoney(refundAmountsData.adjustedRefundAmount[PaymentSources.WORLD_PAY_EBT_CASH]),
              ]}
            />
          )}
        </>
      )}

      {orderCancelPreflightResponse.incompleteInvoicesNum.length > 0 && (
        <ErrorText>
          {`There are one or more invoices associated with this order that have pending payments. Please wait for payment, or void these invoices (${orderCancelPreflightResponse.incompleteInvoicesNum.join(
            ', ',
          )}).`}
        </ErrorText>
      )}

      {/** Confirm Cancellation button */}
      <View style={styles.confirmButton}>
        <Button loading={isLoading} disabled={isLoading} title="Confirm Cancellation" onPress={confirmCancellation} />
      </View>
    </View>
  )
})

const useStyles = () =>
  useSizeFnStyles(({ isExtraSmallDevice, isSmallDevice }) => ({
    refundOptionsCont: {
      marginHorizontal: 8,
      marginBottom: 10,
      marginTop: 5,
    },
    refundOptionsPickerCont: {
      marginTop: 10,
      height: 35,
    },
    pickerCont: {
      height: 35,
      width: isExtraSmallDevice ? 220 : isSmallDevice ? 250 : 280,
    },
    itemInfoCont: {
      flexDirection: 'row',
      marginHorizontal: 12,
    },
    confirmButton: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: 20,
    },
    marginBottom5: {
      marginBottom: 5,
    },
  }))

/** This function is used to decide what should be listed as first/top payment method. */
const arrangePaymentsOrder = (
  currency: CurrencyCode,
  refundAmounts: OrderCancelPreflightResponse['refundAmounts'],
): Omit<Record<keyof OrderCancelPreflightResponse['refundAmounts'], boolean>, 'offline'> => {
  const sourceAmounts = new SourceAmountsTracker(currency, refundAmounts).displayAllSourceAmounts()

  const result = entries(PaymentSources).reduce((acc, [_, source]) => {
    if (source !== PaymentSources.OFFLINE) acc[source] = false
    return acc
  }, {} as Record<keyof OrderCancelPreflightResponse['refundAmounts'], boolean>)

  if (MoneyCalc.isGTZero(sourceAmounts[PaymentSources.STRIPE])) {
    result[PaymentSources.STRIPE] = true
  } else if (MoneyCalc.isGTZero(sourceAmounts[PaymentSources.STRIPE_ACH])) {
    result[PaymentSources.STRIPE_ACH] = true
  } else if (MoneyCalc.isGTZero(sourceAmounts[PaymentSources.FORTIS_CARD_ONLINE])) {
    result[PaymentSources.FORTIS_CARD_ONLINE] = true
  } else if (MoneyCalc.isGTZero(sourceAmounts[PaymentSources.FORTIS_ACH])) {
    result[PaymentSources.FORTIS_ACH] = true
  } else if (MoneyCalc.isGTZero(sourceAmounts[PaymentSources.FARM_CREDIT])) {
    result[PaymentSources.FARM_CREDIT] = true
  } else if (MoneyCalc.isGTZero(sourceAmounts[PaymentSources.WORLD_PAY_EBT_CASH])) {
    result[PaymentSources.WORLD_PAY_EBT_CASH] = true
  } else if (MoneyCalc.isGTZero(sourceAmounts[PaymentSources.WORLD_PAY_EBT_SNAP])) {
    result[PaymentSources.WORLD_PAY_EBT_SNAP] = true
  }

  return result
}
